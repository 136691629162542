var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-completed thank-you-page" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            { staticClass: "text-center" },
            [
              _c("CategoryTitle", { attrs: { category: _vm.category } }),
              _vm.fidelityCard
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("profileConfirmed.fidelityCardText")) +
                        " "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(" " + _vm._s(_vm.fidelityCard) + " ")
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("profileConfirmed.additionalText")))
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }